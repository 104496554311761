<template>
  <TopNav></TopNav>
  <h1>Yüzde Hesaplama</h1>
  <fieldset>
    <legend>Yüzde Hesaplama Aracı</legend>
    <input type="radio" name="f" value="c" id="c" v-model="secili"><label for="c">A sayısının yüzde B'si kaçtır?</label><br>
    <input type="radio" name="f" value="d" id="d" v-model="secili"><label for="d">A sayısı, B sayısının yüzde kaçıdır?</label><br>
    <input type="radio" name="f" value="e" id="e" v-model="secili"><label for="e">A sayısından B sayısına değişim oranı yüzde kaçtır?</label><br>
    <input type="radio" name="f" value="f" id="f" v-model="secili"><label for="f">A sayısı, yüzde B kadar artırılırsa kaç olur?</label><br>
    <input type="radio" name="f" value="g" id="g" v-model="secili"><label for="g">A sayısı, yüzde B kadar azaltılırsa kaç olur?</label><br>
    <br>
    <label for="a">A Sayısı:</label> <input type="number" v-model.number="inputA" id="a" step="any">
    <br>
    <label for="b">B Sayısı:</label> <input type="number" v-model.number="inputB" id="b" step="any">
    <br><br>
      <span v-html="sonuc()"></span>
  </fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'

export default {
  name: "Yuzde",
  data() {
    return {
      inputA: 0,
      inputB: 0,
      secili: "c"
    }
  },
  components: {
    TopNav
  },
  methods: {
    sonuc: function () {
      if (this.secili == "c") {
        var str = this.inputA + " sayısının %" + this.inputB + ": ";
        var sonuc = (this.inputA / 100) * this.inputB;
        sonuc = '<span class="blue">' + sonuc + '</span>';
        return str.concat(sonuc);
      } else if (this.secili == "d") {
        var str = this.inputA + " sayısı " + this.inputB + ' sayısının: <span class="blue">%';
        var sonuc = (this.inputA / this.inputB) * 100;
        sonuc = sonuc + '</span>';
        return str.concat(sonuc);
      } else if (this.secili == "e") {
        var str = '<span class="blue">' + "%";
        if (this.inputA > this.inputB) {
          var sonuc = (1 - (this.inputB / this.inputA)) * 100;
            sonuc = sonuc  + '</span>';
          return str.concat(sonuc).concat(" azalma");
        } else {
          var sonuc = ((this.inputB / this.inputA) * 100) - 100;
          sonuc = sonuc  + '</span>';
          return str.concat(sonuc).concat(" artma");
        }
      } else if (this.secili == "f") {
        return '<span class="blue">' + (this.inputA / 100) * (100 + this.inputB) + '</span>';
      } else {
        return '<span class="blue">' + (this.inputA / 100) * (100 - this.inputB) + '</span>';
      }
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

footer section a {
  margin: 20px;
}

.blue {
    color: blue;
}
</style>
